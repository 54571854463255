@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Acme&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Francois+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Instrument+Sans%3A0%2C400%2C0%2C500%2C0%2C600%2C0%2C700%2C1%2C400%2C1%2C500%2C1%2C600%2C1%2C700%7CInter+Tight%3A0%2C100%2C0%2C200%2C0%2C300%2C0%2C400%2C0%2C500%2C0%2C600%2C0%2C700%2C0%2C800%2C0%2C900%2C1%2C100%2C1%2C200%2C1%2C300%2C1%2C400%2C1%2C500%2C1%2C600%2C1%2C700%2C1%2C800%2C1%2C900&subset=latin%2Clatin-ext&ver=1.0.0);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Acme", sans-serif;
  letter-spacing: 0.8px;
}

body {
  overflow: overlay;
}

.anim-onscroll {
  opacity: 0;
}

.main-page {
  background-color: #000;
}

#progressBar {
  width: 90%;
  margin: 10px auto;
  height: 22px;
  background-color: #0a5f44;
}

#progressBar div {
  height: 100%;
  text-align: right;
  padding: 0 10px;
  line-height: 22px; /* same as #progressBar height if we want text middle aligned */
  width: 0;
  background-color: #cbea00;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 20px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgb(66, 66, 66);
}

html {
  font-size: 100%;
}

hr {
  background-color: white;
}

.section-header {
  color: rgb(219, 147, 12);
  font-family: "Francois One", sans-serif;
  letter-spacing: 5px;
  font-weight: 700;
}

.character-container {
  padding: 60px 0;
  text-align: center;
  font-weight: 700;
  color: black;
  background-color: rgb(17, 19, 19);
  font-family: cursive;
  box-shadow: inset 0px 60px 50px -18px #000000,
    inset 0px -60px 50px -18px #000000;
}

.slick-dots li button:before {
  color: white !important;
}

.slick-container {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

.slider-img {
  height: 300px;
  width: 300px;
  border-radius: 10px;
  border-width: 8px;
  border-style: solid;
  border-color: #424d44;
  background-blend-mode: hard-light;
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

.flip-card {
  text-align: center;
  background-color: transparent;
  width: 300px !important;
  height: 300px;
  border-radius: 10px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 10px;
  text-align: center;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  border-radius: 10px;
  color: black;
}

.flip-card-back {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  border-width: 8px;
  border-style: solid;
  border-color: #424d44;
  color: white;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.card-info {
  font-weight: 500;
  padding: 20px;
  color: rgb(17, 19, 19);
  font-family: inherit;
}

.card-info h5 {
  font-weight: 400;
  font-size: 30px;
  color: rgb(214, 255, 223);
}

.card-info p {
  color: rgb(214, 255, 223);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slide img {
  margin: auto;
}

.login {
  background: #5531ac;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 360px;
  background-color: white;
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  padding: 10px;
}

.login-signup-page-header {
  font-size: 2em;
}

.success-message {
  font-family: "Roboto", sans-serif;
  background-color: #3f89f8;
  padding: 15px;
  color: white;
}

.error {
  border-style: solid;
  border: 2px solid #ffa4a4;
}

a {
  text-decoration: none;
}

/* UNITY PART */
.unity-flex-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(
    circle,
    #515963,
    #414855,
    #33373f,
    #222429,
    #17191b
  );
}

.cannot-play {
  font-size: 3rem;
  text-align: center;
  color: #ffffff;
}

.unity-content {
  position: relative;
  box-shadow: 0px 0px 16px 8px rgba(0, 0, 0, 0.73);
  background-color: #000;
  width: 100%;
  max-width: 150vh;
  /* max-width: 1280px; */
  /* width: 1366px;
  height: 768px; */
  margin: 0 auto;
}

/* QUESTION/TIMER/LEFT SECTION */
.question-section {
  width: 100%;
  position: relative;
}

.question-count {
  /* animation: transitionIn 0.5s; */
  font-family: "Anton", sans-serif;
  text-align: center;
  color: #113e80;
}

.question-question {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
}

.firsthalf {
  font-weight: 600;
  color: #113e80;
}

.question-count span {
  font-size: 28px;
}

.question-text {
  margin-bottom: 12px;
}

.question-card {
  /* animation: transitionIn 0.5s; */
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 7px 0px #000000;
}

.question-card-header {
  background-image: linear-gradient(
    to right,
    #113e80,
    #134389,
    #154792,
    #174c9b,
    #1951a4,
    #1951a4,
    #1951a4,
    #1951a4,
    #174c9b,
    #154792,
    #134389,
    #113e80
  );
  border-color: black;
  color: white;
}

.question-header {
  font-weight: 700;
  word-spacing: 10px;
  font-size: 18px;
  text-align: justify;
  letter-spacing: 1px;
}

.question-body {
  word-wrap: break-word;
  font-size: 16px;
  word-spacing: 8px;
  letter-spacing: 1px;
  color: rgb(56, 56, 56);
  text-align: justify;
}

/* ANSWERS/RIGHT SECTION */
.quiz-instructions {
  text-align: center;
  padding: 30px 0;
}

.quiz-title {
  font-family: "Anton", sans-serif;
  text-align: center;
  color: #001a2c;
}

.quiz-inst1 {
  padding: 20px 0;
  color: #00416c;
}

.start-quiz-button {
  border-color: rgb(0, 0, 0);
  border-width: 2px;
  border-radius: 10px;
  padding: 0 10px;
  font-size: 30px;
  font-weight: 600;
  background-image: linear-gradient(
    to right,
    #113e80,
    #134389,
    #154792,
    #174c9b,
    #1951a4,
    #1951a4,
    #1951a4,
    #1951a4,
    #174c9b,
    #154792,
    #134389,
    #113e80
  );
  color: white;
  transition: 1s;
}

.start-quiz-button:hover {
  color: rgb(182, 182, 182);
}

.answer-section {
  /* animation: transitionIn 0.5s; */
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  position: relative;
}

.quiz-animation {
  -webkit-animation: transitionIn 0.5s;
          animation: transitionIn 0.5s;
}

.quiz-button,
.after-game-button {
  border-color: black;
  border-width: 2px;
  font-weight: 500;
  color: white;
  font-size: 22px;
  border-radius: 5px;
  padding: 5px 20px;
  align-items: center;
  cursor: pointer;
}

.after-game-button {
  font-size: 16px;
  padding: 2px 5px;
  color: black;
  background-color: #9f9ee7;
}

.next-button {
  display: block;
  margin-left: auto;
  margin-right: 0px;
  background-image: linear-gradient(
    to right,
    #113e80,
    #134389,
    #154792,
    #174c9b,
    #1951a4,
    #1951a4,
    #1951a4,
    #1951a4,
    #174c9b,
    #154792,
    #134389,
    #113e80
  );
}

.next-button:hover {
  color: rgb(182, 182, 182);
}

.fake-button {
  background-color: rgb(151, 41, 41);
  display: inline-block;
  width: 50%;
  transition: 1s;
  box-shadow: inset 0px 0px 8px 0px #000000;
}

.real-button {
  background-color: rgb(80, 119, 85);
  display: inline-block;
  width: 50%;
  transition: 1s;
  box-shadow: inset 0px 0px 8px 0px #000000;
}

#fact-check {
  font-size: 1em;
  text-align: justify;
}

.correct {
  background-color: #2f922f;
}

.incorrect {
  background-color: #ff3333;
}

.fake-button:hover,
.real-button:hover {
  background-image: linear-gradient(
    to right,
    #113e80,
    #134389,
    #154792,
    #174c9b,
    #1951a4,
    #1951a4,
    #1951a4,
    #1951a4,
    #174c9b,
    #154792,
    #134389,
    #113e80
  );
}

.quiz-buttonon:focus {
  outline: none;
}

.quiz-button svg {
  margin-right: 5px;
}

.image {
  width: 80%;
  padding: 20px 20px 0px 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.quiz-container {
  margin-bottom: 20px;
  width: 90%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.scrollable {
  height: 450px;
  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

/* TIMER */
.timer {
  margin: 20px 0;
}

.ProgressBar {
  text-align: center;
  height: 30px;
  border-radius: 50px;
  border: 2px solid rgb(0, 0, 0);
}

.fill {
  font-weight: 100;
  letter-spacing: 2px;
  background-image: linear-gradient(
    to right,
    #113e80,
    #134389,
    #154792,
    #174c9b,
    #1951a4,
    #1951a4,
    #1951a4,
    #1951a4,
    #174c9b,
    #154792,
    #134389,
    #113e80
  );
  color: white;
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-out;
}
/* END TIMER */

/* Navigation Bar */
.navbar {
  color: rgb(255, 255, 255);
  padding: 10px 0px;
  width: 100%;
  background-color: rgb(17, 19, 19);
  z-index: 1;
  top: 0;
}

.fixed {
  position: fixed;
}

.footer {
  color: #e2f0ff;
  padding: 20px 0px;
  width: 100%;
  background-color: rgb(17, 19, 19);
  z-index: 1;
}

.nav-container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.nav-item {
  padding: 3px 10px;
  font-size: 20px;
  /* height: 60px; */
}
.nav-logo:hover {
  cursor: pointer;
}

.logo {
  height: 40px;
}

.dropdown .btn {
  padding: 4px 10px;
  margin-right: 15px;
  font-size: 20px;
}
.nav-login {
  float: right;
  transition: all 600ms ease-in-out;
}

.btn-primary {
  color: #fff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  border-color: #007bff;
  cursor: pointer;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  border-color: #000000;
  cursor: pointer;
}

.btn-primary:hover {
  color: #fff;
  background-color: black;
  border-color: #007bff;
}

.nav-play {
  margin-right: 15px;
  float: right;
  border-color: #007bff;
  background-color: #007bff;
  border-radius: 0.25rem;
}

.nav-play:hover {
  background-color: rgb(0, 0, 0);
  cursor: pointer;
}

.login-link {
  text-decoration: none !important;
  color: rgb(255, 255, 255);
}

.login-link:hover {
  text-decoration: none !important;
  color: rgb(255, 255, 255);
}

.video-container {
  background-color: #000;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.video-style {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

/* Login & Sign Up */
.forgot-password-text {
  font-size: 1.2em;
}

.forgot-password-button {
  font-weight: 900;
  background-color: rgb(92, 92, 104);
  color: rgb(17, 17, 19);
  border-color: none;
  border-radius: 5px;
  padding: 5px 15px;
  float: right;
}

.forgot-password-button:hover {
  /* background-color: rgb(0, 235, 235); */
  color: #000;
}

.form-success {
  text-align: center;
  font-size: 18px;
  margin: 20px;
}

.form {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form h5 {
  font-weight: 700;
  text-align: center;
  width: 80%;
  margin-bottom: 1rem;
}

.form-inputs {
  width: 80%;
}

.form-inputs p,
.login-fail {
  font-size: 0.8rem;
  margin-bottom: 0;
  color: #f00e0e;
}

.login-fail {
  font-size: 1rem;
  padding-top: 10px;
  width: 80%;
  margin: 0 auto;
}

.change-password-error-message {
  font-size: 0.8rem;
  color: #f00e0e;
  font-size: 1rem;
}

.form-label {
  font-weight: 500;
  display: inline-block;
  font-size: 0.8rem;
}

.form-input {
  display: block;
  padding-left: 10px;
  outline: none;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  border: none;
}

.sign-up-link {
  margin-top: 1.75em;
  width: 80%;
  text-align: end;
}

.sign-up-link a,
.forgot-password-link a {
  color: rgb(219, 147, 12);
}

.forgot-password-link {
  width: 80%;
  text-align: end;
}

.form-input::-webkit-input-placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input:-ms-input-placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input::placeholder {
  color: #595959;
  font-size: 12px;
}

.form-input-btn {
  width: 80%;
  height: 50px;
  margin-top: 10px;
  border-radius: 2px;
  background: rgb(17, 17, 19);
  outline: none;
  border: none;
  color: #fff;
  font-size: 1rem;
}

.not-submitting-btn {
  background: rgb(17, 17, 19);
}

.submitting-btn {
  background: rgb(48, 48, 53);
  pointer-events: none;
}

.form-input-btn:hover {
  cursor: pointer;
  /* background: rgb(12, 99, 250) 100%; */
  transition: all 0.4s ease-out;
}

.form-input-login {
  font-size: 0.8rem;
  margin-top: 10px;
  color: #fff;
  width: 80%;
  text-align: center;
}

.form-input-login a {
  text-decoration: none;
  color: #27cdff;
  font-weight: 600;
}

.context {
  width: 100%;
  position: absolute;
  top: 50vh;
}

.context h1 {
  text-align: center;
  color: #fff;
  font-size: 50px;
}

.area {
  background: rgb(17, 19, 19);
  width: 100%;
  height: 100vh;
  display: grid;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 100px;
  height: 100px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: animate 18s linear infinite;
          animation: animate 18s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 10%;
  width: 150px;
  height: 150px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 20%;
  width: 70px;
  height: 70px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 12s;
          animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 30%;
  width: 40px;
  height: 40px;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 60%;
  width: 30px;
  height: 30px;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 70%;
  width: 100px;
  height: 100px;
  -webkit-animation-delay: 7s;
          animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 80%;
  width: 25px;
  height: 25px;
  -webkit-animation-delay: 15s;
          animation-delay: 15s;
  -webkit-animation-duration: 45s;
          animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 90%;
  width: 15px;
  height: 15px;
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  -webkit-animation-duration: 35s;
          animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 55%;
  width: 180px;
  height: 180px;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}

.form-background {
  background-color: black;
  height: 100vh;
  display: grid;
}

.form-container {
  z-index: 4;
  width: 85%;
  max-width: 670px;
  margin: 0 auto;
  padding: 30px 0;
  background: rgba(111, 114, 114, 0.5);
  margin: auto;
  color: rgb(219, 147, 12);
}

.play-left-part {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.play-image {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.leaderboard-grid {
  display: grid;
  grid-template-columns: 15% 55% 30%;
  border-bottom: none;
}

.leaderboard-grid-header {
  background-color: rgb(17, 19, 19);
  font-weight: 500;
  font-family: "Anton", sans-serif;
  letter-spacing: 2px;
  color: #e2f0ff;
  padding: 10px 0;
  margin-bottom: 5px;
  font-size: 24px;
}

.leaderboard-grid-data {
  display: grid;
  grid-template-columns: 15% 55% 30%;
  /* border-radius: 8px; */
  border-style: solid;
  border-width: 2px;
  margin-bottom: 4px;
  /* color: rgb(0, 0, 114); */
}

.leaderboard-grid-data span {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: rgb(24, 24, 24);
  color: #e2f0ff;
}

.lb-span-start img {
  height: 36px;
}

.leaderboard-grid-child {
  display: contents;
}

.leaderboard-grid-child:hover span {
  font-weight: 700;
  background-color: rgb(56, 56, 56);
}

.animated-box {
  position: relative;
}

.top1:after {
  background: linear-gradient(120deg, gold, rgb(252, 251, 244));
}

.top1:hover span {
  background-color: rgb(255, 217, 0);
  color: #000;
}

.top2:after {
  background: linear-gradient(120deg, silver, rgb(80, 80, 80));
}

.top2:hover span {
  background-color: silver;
  color: #000;
}

.top3:after {
  background: linear-gradient(120deg, #b08d57, #bba78a);
}

.top3:hover span {
  background-color: #b08d57;
  color: #000;
}

.animated-box:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  background-size: 300% 300%;
  -webkit-clip-path: polygon(
    0% 100%,
    3px 100%,
    3px 3px,
    calc(100% - 3px) 3px,
    calc(100% - 3px) calc(100% - 3px),
    3px calc(100% - 3px),
    3px 100%,
    100% 100%,
    100% 0%,
    0% 0%
  );
          clip-path: polygon(
    0% 100%,
    3px 100%,
    3px 3px,
    calc(100% - 3px) 3px,
    calc(100% - 3px) calc(100% - 3px),
    3px calc(100% - 3px),
    3px 100%,
    100% 100%,
    100% 0%,
    0% 0%
  );
}

.animated-box.in:after {
  -webkit-animation: frame-enter 3s forwards ease-in-out alternate-reverse,
    gradient-animation 2s ease-in-out infinite;
          animation: frame-enter 3s forwards ease-in-out alternate-reverse,
    gradient-animation 2s ease-in-out infinite;
}

.leaderboard {
  max-width: 900px;
  padding: 30px 0;
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.leaderboard-container {
  padding: 20px 0;
  box-shadow: inset 0px 60px 50px -20px #000000,
    inset 0px -60px 50px -20px #000000;
}

.centralised {
  text-align: center;
}

.empty-space {
  height: 59.6px;
}

.connector {
  height: 60px;
  background-color: #0e2841;
}

/* Footer */
.social-icons a {
  font-size: 40px;
  margin-left: 10px;
  margin-right: 10px;
}

.social-icons a:hover {
  color: rgb(189, 188, 188) !important;
}

.footer-items {
  margin-bottom: 30px;
}

.footer-items-title {
  font-family: "Anton", sans-serif;
  letter-spacing: 2px;
}

.copyright {
  padding: 10px 0;
  font-size: 1.1em;
}

.footer-row {
  margin: 20px 0;
}

.play-for-free-container {
  width: 100%;
  height: 70vh;
  box-shadow: inset 50px 50px 50px 50px #000000;
}
.border-box {
  border: 5px solid #fff; /* Creates a solid black border */
  padding: 10px;          /* Adds space between the text and the border */
  display: inline-block;  /* Ensures the box wraps tightly around the text */
  border-radius: 8px;     /* Optional: Rounded corners */
  background-color: transparent; /* Optional: Adds a background color */
  font-size: 18px;        /* Optional: Sets font size */
  font-family: Arial, sans-serif; /* Optional: Font family */
}
.play-for-free-now {
  background-image: url("/static/media/Screenshot (232).a821b247.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

.play-for-free-content {
  line-height: 80%;
  padding-top: 10%;
  word-spacing: -10px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.play-for-free-text {
  margin-bottom: 2rem;
  font-family:"Instrument Sans";
  line-height: 120%;
  font-size: 5em;
  font-weight: 500;
  word-spacing: 5px;
  color: #fff;
  text-shadow: 0 0 5px #000, 0 0 10px #000, 0 0 20px #000,
    0 0 40px rgb(35, 44, 44), 0 0 80px rgb(35, 44, 44), 0 0 90px rgb(35, 44, 44),
    0 0 100px rgb(35, 44, 44), 0 0 150px rgb(35, 44, 44);
}

.play-for-free-note {
  width: 70%;
  margin: 0 auto;
  background-color: red;
  color: white;
  margin-bottom: 1rem;
  word-spacing: 0px;
}

/* .play-for-free-button {
  display: block;
  margin: auto;
  padding: 1rem 1.25rem;
  font-family: sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  text-shadow: -2px 4px 4px #091243, 0 0 10px #00d0ff, inset 1px 1px 1px white;
  color: #1fffff;
  border: 2px solid;
  border-radius: 4px;
  background-color: black;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6), 2px 1px 4px rgba(0, 0, 0, 0.3),
    2px 4px 3px rgba(3, 0, 128, 0.3), 0 0 7px 2px rgb(35, 44, 44),
    inset 0 1px 2px rgba(0, 0, 0, 0.6), inset 2px 1px 4px rgba(0, 0, 0, 0.3),
    inset 2px 4px 3px rgba(3, 0, 128, 0.3), inset 0 0 7px 2px rgb(35, 44, 44);
  animation: flickering 2s infinite; 
} */

/* .play-for-free-button:hover {
  background-color: rgb(0, 20, 56);
} */

#cursor {
  border-left: 0.1em solid #a5c2ac;
  -webkit-animation: blink 0.7s steps(1) infinite;
          animation: blink 0.7s steps(1) infinite;
}

/* Questions Answered */
.close-modal-button {
  /* float: right; */
  margin-top: -10px;
  margin-right: -10px;
  cursor: pointer;
  border: 1px solid #666666;
  border-radius: 30px;
  font-size: 25px;
  text-align: center;
  display: inline-block;
  line-height: 0px;
  padding: 11px 3px;
}

.not-answered-part {
  padding: 30px 40px;
  text-align: center;
}

.correct-answer {
  color: green;
}

.wrong-answer {
  color: red;
}

.paginationBttns {
  padding: 20px 0;
  width: 80%;
  margin: 0 auto;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  border: 1px solid #2b2eff;
  color: #07070c;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #2b2eff;
}

.paginationActive a {
  color: white;
  background-color: #2b2eff;
}

.paginationDisabled a {
  color: grey;
  background-color: grey;
}

.previousBttn {
  margin-right: 8px;
  border-radius: 5px;
  text-decoration: none;
}

.nextBttn {
  margin-left: 8px;
  border-radius: 5px;
}

@-webkit-keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.objective-background {
  padding: 60px 0;
  width: 100%;
  text-align: center;
  /* background: url("./images/boss.jpeg"), rgb(41, 41, 41); */
}

.objective-description {
  width: 80%;
  margin: 0 auto;
  font-size: 24px;
  color: #e2f0ff;
}

.objective {
  display: flex;
  align-items: center;
  justify-content: center;
}

.objective-text {
  width: 70%;
  font-size: 6em;
  word-spacing: 0.2em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #e2dace;
}

/* Profile Page */
.tab-container {
  width: 85%;
  max-width: 670px;
  min-width: 360px;
  margin: 0 auto;
  background: rgb(216, 216, 216);
  margin: auto;
  color: black;
}

.bloc-tabs {
  display: flex;
}

.tabs {
  text-align: center;
  /* width: 50%; */
  background: rgb(97, 97, 97);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}

.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: rgb(179, 179, 179);
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.content-tabs {
  flex-grow: 1;
}
.content {
  background: rgb(179, 179, 179);
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}

.content h2 {
  padding: 0px 0 5px 0px;
}

.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}

.profile-container {
  width: 95%;
  margin: 0 auto;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;

  object-fit: cover;
  object-position: center right;
}

.profile-pic-wel {
  text-align: center;
}

.change-password-save-button {
  padding: 2px 10px;
  margin-top: 10px;
  border-radius: 2px;
  font-size: 1rem;
  color: white;
  outline: none;
}

.cpsb-submitting {
  background-color: rgb(157, 157, 248);
  pointer-events: none;
}

.cpsb-not-submitting {
  background-color: blue;
}

/* .change-password-save-button:hover {
  background-color: rgb(2, 2, 179);
} */
/* END PROFILE PAGE */

/* Page Not Found */
.page-not-found-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-not-found-container p {
  font-size: 3rem;
  color: white;
}

/* End pa */
@-webkit-keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 100%;
  }
}
@keyframes animate {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    -webkit-transform: translateY(-1000px) rotate(720deg);
            transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 100%;
  }
}

@-webkit-keyframes transitionIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  40% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  60% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes transitionIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  40% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }

  60% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes gradient-animation {
  0% {
    background-position: 15% 0%;
  }
  50% {
    background-position: 85% 100%;
  }
  100% {
    background-position: 15% 0%;
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 15% 0%;
  }
  50% {
    background-position: 85% 100%;
  }
  100% {
    background-position: 15% 0%;
  }
}

@-webkit-keyframes frame-enter {
  0% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) calc(100% - 3px),
      3px calc(100% - 3px),
      3px 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
            clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) calc(100% - 3px),
      3px calc(100% - 3px),
      3px 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
  }
  25% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) calc(100% - 3px),
      calc(100% - 3px) calc(100% - 3px),
      calc(100% - 3px) 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
            clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) calc(100% - 3px),
      calc(100% - 3px) calc(100% - 3px),
      calc(100% - 3px) 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
  }
  50% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      100% 0%,
      0% 0%
    );
            clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      100% 0%,
      0% 0%
    );
  }
  75% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      3px 3px,
      3px 3px,
      3px 3px,
      3px 3px,
      3px 3px,
      3px 0%,
      0% 0%
    );
  }
  100% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      0% 100%
    );
  }
}

@keyframes frame-enter {
  0% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) calc(100% - 3px),
      3px calc(100% - 3px),
      3px 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
            clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) calc(100% - 3px),
      3px calc(100% - 3px),
      3px 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
  }
  25% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) calc(100% - 3px),
      calc(100% - 3px) calc(100% - 3px),
      calc(100% - 3px) 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
            clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) calc(100% - 3px),
      calc(100% - 3px) calc(100% - 3px),
      calc(100% - 3px) 100%,
      100% 100%,
      100% 0%,
      0% 0%
    );
  }
  50% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      100% 0%,
      0% 0%
    );
            clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      calc(100% - 3px) 3px,
      100% 0%,
      0% 0%
    );
  }
  75% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 3px,
      3px 3px,
      3px 3px,
      3px 3px,
      3px 3px,
      3px 3px,
      3px 0%,
      0% 0%
    );
  }
  100% {
    -webkit-clip-path: polygon(
      0% 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      3px 100%,
      0% 100%
    );
  }
}

@-webkit-keyframes flickering {
  0% {
    border-color: transparent;
    opacity: 0.2;
  }
  2% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
  4% {
    border-color: transparent;
    opacity: 0.2;
  }
  8% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
  28% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
  30% {
    border-color: transparent;
    opacity: 0.2;
  }
  36% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
  100% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
}

@keyframes flickering {
  0% {
    border-color: transparent;
    opacity: 0.2;
  }
  2% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
  4% {
    border-color: transparent;
    opacity: 0.2;
  }
  8% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
  28% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
  30% {
    border-color: transparent;
    opacity: 0.2;
  }
  36% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
  100% {
    border-color: rgb(35, 44, 44);
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .section-header {
    font-size: 2.7em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-header {
    font-size: 2.5em;
  }
}

@media (min-width: 501px) and (max-width: 991px) {
  .section-header {
    font-size: 2.2em;
  }

  .objective-text {
    width: 60%;
    font-size: 1.3em;
  }

  .play-for-free-text {
    font-size: 4.5em;
    word-spacing: 5px;
  }

  .paginationBttns a {
    padding: 6px;
  }
}

@media (max-width: 501px) {
  .slider-img {
    height: 200px;
    width: 200px;
  }

  .flip-card {
    width: 200px !important;
    height: 200px;
  }

  .flip-card-inner {
    width: 200px;
    height: 200px;
  }

  .card-info {
    padding: 20px;
    font-size: 16px;
  }

  .card-info h5 {
    font-size: 20px;
  }

  .card-info p {
    font-size: 10px;
  }

  .leaderboard-grid-header {
    padding: 10px 0;
    font-size: 15px;
    letter-spacing: 0px;
  }

  .leaderboard-grid-data span {
    padding: 8px 0;
    font-size: 15px;
  }

  .lb-span-start img {
    height: 30px;
  }

  .leaderboard {
    width: 85%;
  }

  .section-header {
    font-size: 2em;
  }

  .objective-text {
    width: 60%;
    font-size: 1.2em;
  }

  .play-for-free-text {
    font-size: 4em;
    word-spacing: 5px;
  }

  .paginationBttns a {
    padding: 3px;
  }
}

@media (max-width: 300px) {
  .leaderboard-grid-data span {
    padding: 8px 0;
    font-size: 12px;
  }

  .paginationBttns a {
    padding: 3px;
    font-size: 10px;
  }
}

/* PlayForFreeComponent.css */

.play-for-free-text, .play-for-free-button {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); /* Slight upward movement */
    transition: opacity 0.6s ease-out, -webkit-transform 0.6s ease-out;
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
    transition: opacity 0.6s ease-out, transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
  }
  
  .fade-in {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0); /* Element moves to its final position */
  }
  
  .hidden {
    opacity: 0;
    -webkit-transform: translateY(20px);
            transform: translateY(20px); /* Initial hidden state */
  }
  
.hollow-button {
    border: 2px solid #000;         /* Hollow border */
    background-color: #000;         /* Initial background color */
    color: #fff;                    /* Initial text color */
    padding: 10px 20px;             /* Button padding */
    font-size: 16px;                /* Text size */
    cursor: pointer;                /* Pointer cursor on hover */
    position: relative;             /* Needed for pseudo-element positioning */
    overflow: hidden;               /* Hide the overflowing background animation */
    border-radius: 8px;             /* Optional: Rounds the button corners */
    transition: color 0.3s ease, background-color 0.5s ease; /* Smooth transitions */
  }
  
  .hollow-button .login-link {
    color: #fff;                    /* Initial text color for the link */
    text-decoration: none;           /* Remove underline */
    position: relative;             /* Ensure the link stays above the animated background */
    z-index: 1;                     /* Keep the link above the pseudo-element */
    transition: color 0.3s ease;    /* Smooth transition for text color */
  }
  
  .hollow-button::before {
    content: '';                    /* Pseudo-element for background */
    position: absolute;
    top: 100%;                      /* Start off-screen below the button */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;         /* Fill color */
    z-index: 0;                     /* Ensure it stays behind the text */
    transition: top 0.5s ease;      /* Smooth animation for background fill */
  }
  
  .hollow-button:hover::before {
    top: 0;                         /* Fill from bottom to top on hover */
  }
  
  .hollow-button:hover {
    background-color: #fff;         /* Background turns white */
  }
  
  .hollow-button:hover .login-link {
    color: #000;                    /* Change link text color to black on hover */
  }
  
