/* PlayForFreeComponent.css */

.play-for-free-text, .play-for-free-button {
    opacity: 0;
    transform: translateY(20px); /* Slight upward movement */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .fade-in {
    opacity: 1;
    transform: translateY(0); /* Element moves to its final position */
  }
  
  .hidden {
    opacity: 0;
    transform: translateY(20px); /* Initial hidden state */
  }
  