.hollow-button {
    border: 2px solid #000;         /* Hollow border */
    background-color: #000;         /* Initial background color */
    color: #fff;                    /* Initial text color */
    padding: 10px 20px;             /* Button padding */
    font-size: 16px;                /* Text size */
    cursor: pointer;                /* Pointer cursor on hover */
    position: relative;             /* Needed for pseudo-element positioning */
    overflow: hidden;               /* Hide the overflowing background animation */
    border-radius: 8px;             /* Optional: Rounds the button corners */
    transition: color 0.3s ease, background-color 0.5s ease; /* Smooth transitions */
  }
  
  .hollow-button .login-link {
    color: #fff;                    /* Initial text color for the link */
    text-decoration: none;           /* Remove underline */
    position: relative;             /* Ensure the link stays above the animated background */
    z-index: 1;                     /* Keep the link above the pseudo-element */
    transition: color 0.3s ease;    /* Smooth transition for text color */
  }
  
  .hollow-button::before {
    content: '';                    /* Pseudo-element for background */
    position: absolute;
    top: 100%;                      /* Start off-screen below the button */
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;         /* Fill color */
    z-index: 0;                     /* Ensure it stays behind the text */
    transition: top 0.5s ease;      /* Smooth animation for background fill */
  }
  
  .hollow-button:hover::before {
    top: 0;                         /* Fill from bottom to top on hover */
  }
  
  .hollow-button:hover {
    background-color: #fff;         /* Background turns white */
  }
  
  .hollow-button:hover .login-link {
    color: #000;                    /* Change link text color to black on hover */
  }
  